import React from 'react';
import PropTypes from 'prop-types';
import imageUrlFor from '@helpers/imageUrlFor';
import BlogListing from '@components/BlogListing';
import Components from '@components/Components';
import HeroSecondary from '@components/HeroSecondary';
import Link from '@utility/Link';
import ScrollInView from '@utility/ScrollInView';
import SEO from '@components/seo';

const BlogPostTemplate = ({
  pageContext: {
    id,
    _type,
    author,
    blogListing,
    category,
    description,
    date,
    image,
    title,
    video,
    _rawComponents,
    _rawCredits,
  },
}) => (
  <div className="mb-30">
    <SEO
      title={title}
      description={description || null}
      image={imageUrlFor(image).url() || null}
    />
    <HeroSecondary data={{ image, video }} />
    <div className="wrapper">
      <ScrollInView>
        <div className="row">
          <div className="col-12 lg:col-10 lg:offset-1">
            <ul className="flex flex-wrap mb-6 md:mb-8 type-sans-030 text-mono-500">
              <li className="mr-1">
                <Link
                  to="/blog"
                  styled={false}
                  className="no-underline-link after:h-[75%]"
                  aria-label="Go back to Stories"
                >
                  Stories
                </Link>{' '}
                /
              </li>
              <li className="mr-1">
                <Link
                  to={`/${category.slug.current}`}
                  styled={false}
                  className="no-underline-link after:h-[75%]"
                  aria-label={`Go back to ${category.title}`}
                >
                  {category.title}
                </Link>{' '}
                /
              </li>
              <li>{title}</li>
            </ul>
          </div>
        </div>
      </ScrollInView>
      <ScrollInView>
        <div className="row">
          <div className="col-12 lg:col-8 lg:offset-1">
            <h1 className="mb-6 md:mb-8 type-sans-830 xl:type-sans-1130">
              {title}
            </h1>
            <p className="mb-1 type-upper-040 text-mono-500">{date}</p>
            {author && <p className="type-upper-040 text-mono-500">{author}</p>}
          </div>
        </div>
      </ScrollInView>
    </div>
    {_rawComponents &&
      _rawComponents.map((element) => (
        <div className="py-12 md:py-20" key={element._key}>
          {React.createElement(Components(element._type), {
            key: element._key,
            data: element,
          })}
        </div>
      ))}
    <div className="wrapper">
      {_rawCredits && (
        <ScrollInView className="py-12 md:py-20">
          {_rawCredits.map((credit) => (
            <div key={credit._key} className="row mb-4">
              <div className="col-4 offset-2 lg:col-2 lg:offset-5">
                <p className="type-sans-230">{credit.role}</p>
              </div>
              <div className="col-4 lg:col-5">
                {credit.link && (
                  <Link
                    to={credit.link[0]}
                    internalExternal
                    className="type-sans-240"
                    aria-label={credit.link[0].label}
                  >
                    {credit.link[0].label}
                  </Link>
                )}
              </div>
            </div>
          ))}
        </ScrollInView>
      )}
      <ScrollInView className="pt-12 md:pt-20 mb-6 md:mb-30">
        <div className="row">
          <div className="col-12 lg:col-10 lg:offset-1">
            <h2 className="type-sans-630 md:type-sans-830 xl:type-sans-930">
              More Stories
            </h2>
          </div>
        </div>
      </ScrollInView>
      <BlogListing data={blogListing !== null ? blogListing : {}} />
    </div>
  </div>
);

BlogPostTemplate.propTypes = {
  pageContext: PropTypes.shape({
    id: '',
    _type: PropTypes.string,
    author: PropTypes.string,
    blogListing: PropTypes.object,
    category: PropTypes.object.isRequired,
    date: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    video: PropTypes.object,
    _rawComponents: PropTypes.array,
    _rawCredits: PropTypes.array,
    description: PropTypes.string,
  }),
};

BlogPostTemplate.defaultProps = {
  pageContext: {
    id: '',
    _type: '',
    author: '',
    blogListing: {},
    category: {},
    date: '',
    image: {},
    title: '',
    video: {},
    description: '',
    _rawComponents: [],
    _rawCredits: [],
  },
};

export default BlogPostTemplate;
